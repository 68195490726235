import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const Contact = () => {
  const [data, setData] = useState({
    first: "",
    last: "",
    email: "",
    country: "",
    more: "",
  })

  const [confirmation, setConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateField = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    fetch(
      `https://api.hubapi.com/contacts/v1/contact/createOrUpdate/email/${data.email}/?hapikey=b25738dd-a3f0-47dd-9cea-a968af36248d`,
      {
        method: "post",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          properties: [
            {
              property: "firstname",
              value: data.first,
            },
            {
              property: "lastname",
              value: data.last,
            },
            {
              property: "country",
              value: data.country,
            },
            {
              property: "message",
              value: data.more,
            },
          ],
        }),
      }
    )
      .then(response => {
        setConfirmation(true)
      })
      .catch(error => {
        console.log("error", error)
      })
  }
  return (
    <Layout>
      <SEO title="obvıous. contact" />
      <div className="relative pb-12">
        <div className="absolute z-0 md:z-10 inset-0 opacity-10 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="3724"
            height="609"
            viewBox="0 0 3724 609"
          >
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
              <g transform="translate(1209)">
                <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
                <circle cx="642" cy="158" r="20" fill="#00B1A9" />
                <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
                <circle cx="131" cy="250" r="34" fill="#00B1A9" />
                <circle cx="403" cy="562" r="19" fill="#00B1A9" />
                <circle cx="707" cy="253" r="35" fill="#00B1A9" />
                <circle cx="285" cy="199" r="43" fill="#00B1A9" />
                <circle cx="129" cy="74" r="28" fill="#75DDDD" />
                <circle cx="928" cy="256" r="35" fill="#508991" />
                <circle cx="477" cy="279" r="34" fill="#00B1A9" />
                <circle cx="57" cy="532" r="42" fill="#75DDDD" />
                <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
                <circle cx="172" cy="558" r="23" fill="#00B1A9" />
                <circle cx="20" cy="278" r="20" fill="#508991" />
                <circle cx="671" cy="550" r="26" fill="#508991" />
                <circle cx="367" cy="168" r="11" fill="#75DDDD" />
                <circle cx="840" cy="48" r="48" fill="#00B1A9" />
                <circle cx="261" cy="482" r="20" fill="#508991" />
                <circle cx="828" cy="50" r="29" fill="#508991" />
                <circle cx="891" cy="467" r="40" fill="#75DDDD" />
                <circle cx="442" cy="214" r="18" fill="#508991" />
                <circle cx="536" cy="264" r="29" fill="#508991" />
                <circle cx="104" cy="344" r="49" fill="#75DDDD" />
                <circle cx="703" cy="571" r="38" fill="#508991" />
                <circle cx="278" cy="313" r="33" fill="#00B1A9" />
                <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
                <circle cx="58" cy="374" r="50" fill="#75DDDD" />
                <circle cx="602" cy="465" r="42" fill="#00B1A9" />
                <circle cx="608" cy="507" r="29" fill="#508991" />
                <circle cx="555" cy="109" r="13" fill="#00B1A9" />
                <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
                <circle cx="221" cy="413" r="36" fill="#00B1A9" />
                <circle cx="1042" cy="165" r="18" fill="#508991" />
                <circle cx="666" cy="279" r="30" fill="#75DDDD" />
                <circle cx="1063" cy="100" r="26" fill="#508991" />
                <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
                <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
                <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
                <circle cx="287" cy="560" r="12" fill="#508991" />
                <circle cx="119" cy="238" r="24" fill="#75DDDD" />
                <circle cx="328" cy="571" r="14" fill="#508991" />
                <circle cx="888" cy="456" r="38" fill="#508991" />
                <circle cx="906" cy="73" r="16" fill="#00B1A9" />
                <circle cx="799" cy="257" r="41" fill="#00B1A9" />
                <circle cx="965" cy="367" r="11" fill="#75DDDD" />
                <circle cx="504" cy="50" r="12" fill="#75DDDD" />
                <circle cx="891" cy="379" r="21" fill="#75DDDD" />
                <circle cx="629" cy="218" r="11" fill="#508991" />
                <circle cx="757" cy="500" r="40" fill="#75DDDD" />
              </g>
              <g transform="matrix(1 0 0 -1 2482 609)">
                <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
                <circle cx="642" cy="158" r="20" fill="#00B1A9" />
                <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
                <circle cx="131" cy="250" r="34" fill="#00B1A9" />
                <circle cx="403" cy="562" r="19" fill="#00B1A9" />
                <circle cx="707" cy="253" r="35" fill="#00B1A9" />
                <circle cx="285" cy="199" r="43" fill="#00B1A9" />
                <circle cx="129" cy="74" r="28" fill="#75DDDD" />
                <circle cx="928" cy="256" r="35" fill="#508991" />
                <circle cx="477" cy="279" r="34" fill="#00B1A9" />
                <circle cx="57" cy="532" r="42" fill="#75DDDD" />
                <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
                <circle cx="172" cy="558" r="23" fill="#00B1A9" />
                <circle cx="20" cy="278" r="20" fill="#508991" />
                <circle cx="671" cy="550" r="26" fill="#508991" />
                <circle cx="367" cy="168" r="11" fill="#75DDDD" />
                <circle cx="840" cy="48" r="48" fill="#00B1A9" />
                <circle cx="261" cy="482" r="20" fill="#508991" />
                <circle cx="828" cy="50" r="29" fill="#508991" />
                <circle cx="891" cy="467" r="40" fill="#75DDDD" />
                <circle cx="442" cy="214" r="18" fill="#508991" />
                <circle cx="536" cy="264" r="29" fill="#508991" />
                <circle cx="104" cy="344" r="49" fill="#75DDDD" />
                <circle cx="703" cy="571" r="38" fill="#508991" />
                <circle cx="278" cy="313" r="33" fill="#00B1A9" />
                <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
                <circle cx="58" cy="374" r="50" fill="#75DDDD" />
                <circle cx="602" cy="465" r="42" fill="#00B1A9" />
                <circle cx="608" cy="507" r="29" fill="#508991" />
                <circle cx="555" cy="109" r="13" fill="#00B1A9" />
                <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
                <circle cx="221" cy="413" r="36" fill="#00B1A9" />
                <circle cx="1042" cy="165" r="18" fill="#508991" />
                <circle cx="666" cy="279" r="30" fill="#75DDDD" />
                <circle cx="1063" cy="100" r="26" fill="#508991" />
                <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
                <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
                <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
                <circle cx="287" cy="560" r="12" fill="#508991" />
                <circle cx="119" cy="238" r="24" fill="#75DDDD" />
                <circle cx="328" cy="571" r="14" fill="#508991" />
                <circle cx="888" cy="456" r="38" fill="#508991" />
                <circle cx="906" cy="73" r="16" fill="#00B1A9" />
                <circle cx="799" cy="257" r="41" fill="#00B1A9" />
                <circle cx="965" cy="367" r="11" fill="#75DDDD" />
                <circle cx="504" cy="50" r="12" fill="#75DDDD" />
                <circle cx="891" cy="379" r="21" fill="#75DDDD" />
                <circle cx="629" cy="218" r="11" fill="#508991" />
                <circle cx="757" cy="500" r="40" fill="#75DDDD" />
              </g>
              <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
              <circle cx="642" cy="158" r="20" fill="#00B1A9" />
              <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
              <circle cx="131" cy="250" r="34" fill="#00B1A9" />
              <circle cx="403" cy="562" r="19" fill="#00B1A9" />
              <circle cx="707" cy="253" r="35" fill="#00B1A9" />
              <circle cx="285" cy="199" r="43" fill="#00B1A9" />
              <circle cx="129" cy="74" r="28" fill="#75DDDD" />
              <circle cx="928" cy="256" r="35" fill="#508991" />
              <circle cx="477" cy="279" r="34" fill="#00B1A9" />
              <circle cx="57" cy="532" r="42" fill="#75DDDD" />
              <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
              <circle cx="172" cy="558" r="23" fill="#00B1A9" />
              <circle cx="20" cy="278" r="20" fill="#508991" />
              <circle cx="671" cy="550" r="26" fill="#508991" />
              <circle cx="367" cy="168" r="11" fill="#75DDDD" />
              <circle cx="840" cy="48" r="48" fill="#00B1A9" />
              <circle cx="261" cy="482" r="20" fill="#508991" />
              <circle cx="828" cy="50" r="29" fill="#508991" />
              <circle cx="891" cy="467" r="40" fill="#75DDDD" />
              <circle cx="442" cy="214" r="18" fill="#508991" />
              <circle cx="536" cy="264" r="29" fill="#508991" />
              <circle cx="104" cy="344" r="49" fill="#75DDDD" />
              <circle cx="703" cy="571" r="38" fill="#508991" />
              <circle cx="278" cy="313" r="33" fill="#00B1A9" />
              <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
              <circle cx="58" cy="374" r="50" fill="#75DDDD" />
              <circle cx="602" cy="465" r="42" fill="#00B1A9" />
              <circle cx="608" cy="507" r="29" fill="#508991" />
              <circle cx="555" cy="109" r="13" fill="#00B1A9" />
              <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
              <circle cx="221" cy="413" r="36" fill="#00B1A9" />
              <circle cx="1042" cy="165" r="18" fill="#508991" />
              <circle cx="666" cy="279" r="30" fill="#75DDDD" />
              <circle cx="1063" cy="100" r="26" fill="#508991" />
              <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
              <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
              <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
              <circle cx="287" cy="560" r="12" fill="#508991" />
              <circle cx="119" cy="238" r="24" fill="#75DDDD" />
              <circle cx="328" cy="571" r="14" fill="#508991" />
              <circle cx="888" cy="456" r="38" fill="#508991" />
              <circle cx="906" cy="73" r="16" fill="#00B1A9" />
              <circle cx="799" cy="257" r="41" fill="#00B1A9" />
              <circle cx="965" cy="367" r="11" fill="#75DDDD" />
              <circle cx="504" cy="50" r="12" fill="#75DDDD" />
              <circle cx="891" cy="379" r="21" fill="#75DDDD" />
              <circle cx="629" cy="218" r="11" fill="#508991" />
              <circle cx="757" cy="500" r="40" fill="#75DDDD" />
            </g>
          </svg>
        </div>
        <div className="relative pt-64 max-w-3xl text-center mx-auto">
          <h1 className="font-semibold text-2xl md:text-4xl text-white">
            Contact our <span className="text-brand-grey-dark">team.</span>
          </h1>
          <p className="font-medium text-base md:text-lg mt-6 leading-normal text-brand-grey-dark max-w-2xl mx-auto">
            Our team is happy to answer your questions. Fill out the form below
            and we'll be in touch as soon as possible.
          </p>
        </div>
      </div>
      <div className="flex flex-col flex-grow relative">
        <div className="w-full bg-brand-teal absolute top-0 z-0">
          <svg
            viewBox="0 0 50 15"
            preserveAspectRatio="none"
            className="w-full bottom-0 h-32 md:h-76"
            style={{ marginBottom: "-1px" }}
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path
                d="M0,14.9954241 C19.9497878,5.17408693 35.0012698,0.175684169 49.965204,0 C49.9695893,5.37969228 50.0383731,14.9954241 49.9695893,14.9954241 C38.8635973,14.9954241 22.2070676,14.9954241 0,14.9954241 Z"
                fill="#E8FFFE"
                fill-rule="nonzero"
              ></path>
            </g>
          </svg>
        </div>
        <div
          className={`h-full flex-grow bg-brand-teal-light ${
            confirmation ? "pb-32" : "pb-16"
          }`}
        >
          <div className="max-w-4xl mx-auto relative px-4 md:px-0">
            {!confirmation && (
              <form
                method="post"
                enctype="application/x-www-form-urlencoded"
                className="bg-white md:rounded-xl md:shadow-lg w-full relative z-20"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-wrap items-center -mx-6 ">
                  <div className="w-full md:w-2/5 px-6 mb-6">
                    <label
                      className="text-gray-600 text-sm font-medium"
                      htmlFor="name"
                    >
                      Your first name
                    </label>
                  </div>
                  <div className="w-full md:w-3/5 px-6 mb-6">
                    <input
                      className="bg-gray-100 border border-gray-100 px-6 py-3 w-full focus:outline-none focus:bg-white focus:border-gray-400 rounded"
                      placeholder="Joe"
                      type="text"
                      name="first"
                      onChange={updateField}
                      value={data.first}
                      required
                    />
                  </div>
                  <div className="w-full md:w-2/5 px-6 mb-6">
                    <label
                      className="text-gray-600 text-sm font-medium"
                      htmlFor="name"
                    >
                      Your last name
                    </label>
                  </div>
                  <div className="w-full md:w-3/5 px-6 mb-6">
                    <input
                      className="bg-gray-100 border border-gray-100 px-6 py-3 w-full focus:outline-none focus:bg-white focus:border-gray-400 rounded"
                      placeholder="Smith"
                      type="text"
                      name="last"
                      onChange={updateField}
                      value={data.last}
                      required
                    />
                  </div>
                  <div className="w-full md:w-2/5 px-6 mb-6">
                    <label
                      className="text-gray-600 text-sm font-medium"
                      htmlFor="name"
                    >
                      Your work email
                    </label>
                  </div>
                  <div className="w-full md:w-3/5 px-6 mb-6">
                    <input
                      className="bg-gray-100 border border-gray-100 px-6 py-3 w-full focus:outline-none focus:bg-white focus:border-gray-400 rounded"
                      placeholder="joe.smith@email.com"
                      type="text"
                      name="email"
                      onChange={updateField}
                      value={data.email}
                      required
                    />
                  </div>
                  <div className="w-full md:w-2/5 px-6 mb-6">
                    <label
                      className="text-gray-600 text-sm font-medium"
                      htmlFor="name"
                    >
                      Country
                    </label>
                  </div>
                  <div className="w-full md:w-3/5 px-6 mb-6">
                    <input
                      className="bg-gray-100 border border-gray-100 px-6 py-3 w-full focus:outline-none focus:bg-white focus:border-gray-400 rounded"
                      placeholder="Country"
                      type="text"
                      name="country"
                      onChange={updateField}
                      value={data.country}
                      required
                    />
                  </div>
                  <div className="w-full md:w-2/5 px-6 mb-6">
                    <label
                      className="text-gray-600 text-sm font-medium"
                      htmlFor="name"
                    >
                      Anything else?
                    </label>
                  </div>
                  <div className="w-full md:w-3/5 px-6 mb-6">
                    <textarea
                      className="bg-gray-100 border border-gray-100 px-6 py-3 w-full focus:outline-none focus:bg-white focus:border-gray-400 rounded h-24"
                      placeholder="Tell us more about your project, needs, and timeline."
                      name="more"
                      onChange={updateField}
                      value={data.more}
                    />
                  </div>
                </div>
                <div>
                  <button
                    disabled={loading}
                    class="block w-full md:w-auto px-6 py-3  rounded-lg no-underline shadow-md  transition-1/4 hover:shadow-lg font-medium text-white bg-brand-grey-dark"
                  >
                    {loading ? "submiting..." : "Contact obvious."}
                  </button>
                </div>
              </form>
            )}
            {confirmation && (
              <div className="bg-white md:rounded-xl md:shadow-lg w-full relative z-20 text-center py-12">
                <h1 className="font-semibold text-2xl md:text-4xl text-brand-teal">
                  Thank you!
                </h1>
                <p className="font-medium text-base md:text-lg mt-3 leading-normal text-brand-grey-dark max-w-2xl mx-auto">
                  We'll get back to you as soon as possible at {data.email}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="bg-brand-teal-light -mb-1">
          <svg
            version="1.1"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            className="h-24 w-full"
          >
            <g fill="#101821">
              <path d="M0 100 C40 0 60 0 100 100 Z"></path>
            </g>
          </svg>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
